import React from 'react';
import styled from '@emotion/styled';
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share';
import Helmet from 'react-helmet';

export const SharingButtons = styled.section`
  position: relative;
  margin-right: 0;
  padding: 0 100px 0;
`

export const SharingButtonsGroup = styled.div`
  position: relative;
`

export const SharingButtonsList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 200px;
  
  display: -webkit-flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
`

export const SharingButtonsItem = styled.li`
  margin: 0.3em 0.3em;

  -webkit-flex: none;
  flex: none;
`

interface SharingButtonsProps {
  url: string;
  title: string;
}

const PostSharing: React.FC<SharingButtonsProps> = ({ url, title }) => (
  <SharingButtons>
    <SharingButtonsGroup>
      <SharingButtonsList>
        <SharingButtonsItem>
          <FacebookShareButton url={url}>
            <a
              target="_blank"
              title="この記事をFacebookでシェア"
              rel="noopener noreferrer"
              style={{cursor: 'pointer'}}
            >
              <FacebookIcon size={40} borderRadius={3} />
            </a>
          </FacebookShareButton>
        </SharingButtonsItem>
        <SharingButtonsItem>
          <TwitterShareButton title={title} url={url}>
            <a
              target="_blank"
              title="この記事をTwitterでシェア"
              rel="noopener noreferrer"
              style={{cursor: 'pointer'}}
            >
              <TwitterIcon size={40} borderRadius={3} />
            </a>
          </TwitterShareButton>
        </SharingButtonsItem>
        <SharingButtonsItem>
          <Helmet>
            <script type="text/javascript" src="//b.st-hatena.com/js/bookmark_button.js" />
          </Helmet>
          <a
            href="https://b.hatena.ne.jp/entry/"
            className="hatena-bookmark-button"
            data-hatena-bookmark-layout="touch"
            data-hatena-bookmark-lang="ja"
            title="この記事をはてなブックマークに追加"
          >
            <img
              width="40"
              height="40"
              src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
              alt="この記事をはてなブックマークに追加"
            />
          </a>
        </SharingButtonsItem>
      </SharingButtonsList>
    </SharingButtonsGroup>
  </SharingButtons>
);

export default PostSharing;